import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Breakpoints'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Breakpoints`}</h1>
    <p>{`The breakpoints tools are a simplified version of the `}<a parentName="p" {...{
        "href": "https://material.io/design/layout/responsive-layout-grid.html#breakpoints"
      }}>{`Material specification`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A breakpoint is the range of predetermined screen sizes that have specific
layout requirements. At a given breakpoint range, the layout adjusts to suit
the screen size and orientation."`}</p>
    </blockquote>
    <p>{`The theme object defines breakpoints for 6 screen ranges with the following
default values:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`xs`}</inlineCode>{` (extra small) of `}<inlineCode parentName="li">{`0px`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`sm`}</inlineCode>{` (small) of `}<inlineCode parentName="li">{`600px`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`md`}</inlineCode>{` (medium) of `}<inlineCode parentName="li">{`960px`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lg`}</inlineCode>{` (large) of `}<inlineCode parentName="li">{`1280px`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`xl`}</inlineCode>{` (extra large) of `}<inlineCode parentName="li">{`1920px`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`They can be configured with different values optionally:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme } from '@arwes/design';

const theme = createTheme({
  // In this case, the "small" and "medium" breakpoints
  // are updated accordingly.
  breakpoints: {
    values: {
      sm: 400,
      md: 768
    }
  }
});
`}</code></pre>
    <p>{`These breakpoints values can be used to create `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Media_Queries"
      }}>{`CSS media queries`}</a>{`
dynamically. The breakpoints functions can be executed in the components dynamic
styles generation to add specific media queries or browser utilities like
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia"
      }}>{`matchMedia`}</a>{`.`}</p>
    <h2>{`Breakpoint Up`}</h2>
    <p>{`To create a media query from one breakpoint upwards:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`theme.breakpoints.up('lg');
// '@media screen and (min-width: 1920px)'
`}</code></pre>
    <p>{`Upwards large using default values.`}</p>
    <h2>{`Breakpoint Down`}</h2>
    <p>{`To create a media query from one breakpoint downwards minus `}<inlineCode parentName="p">{`1`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`theme.breakpoints.down('lg');
// '@media screen and (max-width: 1919px)'
`}</code></pre>
    <p>{`Downwards large breakpoint using default values.`}</p>
    <h2>{`Only Breakpoint`}</h2>
    <p>{`To create a media query between the provided breakpoint and just before the next:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`theme.breakpoints.only('sm');
// '@media screen and (min-width: 600px) and (max-width: 959px)'
`}</code></pre>
    <p>{`From small to medium range using default values.`}</p>
    <h2>{`Between Breakpoints`}</h2>
    <p>{`To create a media query between two provided breakpoints:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`theme.breakpoints.between('md', 'lg');
// '@media screen and (min-width: 960px) and (max-width: 1919px)'
`}</code></pre>
    <p>{`From medium to large range using default values.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      